<template>
  <v-dialog max-width="600" scrollable v-model="dialog" persistent>

    <template v-slot:activator="{ on: menu, attrs }">
      <v-btn text
             @click="[getPhones(),getServices()]"
             color="grey"
             v-bind="attrs"
             v-on="{ ...menu }">
        <v-icon left>mdi-filter-outline</v-icon>
        Filtre
      </v-btn>
    </template>

    <v-card>

      <v-card-title>
        Filtrer par
        <v-spacer/>
        <v-icon @click="dialog = false">mdi-close</v-icon>
      </v-card-title>
      <v-divider/>

      <v-card-text class="pa-8">
        <v-row>
          <v-col cols="12">

            <v-radio-group v-model="filter.payment_mode"
                           row
                           label="Méthode de paiement :">
              <v-radio key="all" label="Tout" value=""></v-radio>
              <v-radio label="Gifty" :value="'gifty'"></v-radio>
              <v-radio label="CIB" :value="'cib'"></v-radio>
            </v-radio-group>


            <v-autocomplete outlined dense
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            clearable
                            :items="users1"
                            v-model="filter.accounts"
                            :item-text="'name'"
                            item-value="account_id"
                            label="Compte..."
                            :search-input.sync="search1"
                            :loading="loadingUsers1"
                            no-data-text="Aucun compte trouvé"
                            hide-no-data
            />

            <v-select outlined dense
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      clearable
                      :items="types"
                      v-model="filter.types"
                      :item-text="'name'"
                      :item-value="'id'"
                      label="Type..."
            >
            </v-select>

            <v-select outlined dense
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      clearable
                      :items="status"
                      v-model="filter.status"
                      :item-text="'label'"
                      :item-value="'value'"
                      label="Status"
                      hide-details
            >
            </v-select>
          </v-col>

          <v-col v-if="in_array(filter.types, 'sent_refill')">
            <v-autocomplete outlined dense
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            clearable
                            :items="users2"
                            v-model="filter.emetters"
                            :item-text="'name'"
                            item-value="account_id"
                            label="Emetteur..."
                            :search-input.sync="search2"
                            :loading="loadingUsers2"
                            no-data-text="Aucun compte trouvé"
                            hide-no-data
            >
            </v-autocomplete>
          </v-col>

          <v-col v-if="in_array(filter.types, 'received_refill')">
            <v-autocomplete outlined dense
                            multiple
                            chips
                            deletable-chips
                            small-chips
                            clearable
                            :items="users3"
                            v-model="filter.receptters"
                            :item-text="'name'"
                            item-value="account_id"
                            label="Recepteur..."
                            :search-input.sync="search3"
                            :loading="loadingUsers3"
                            no-data-text="Aucun compte trouvé"
                            hide-no-data
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-select outlined dense
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      clearable
                      :items="services"
                      v-model="filter.services"
                      :item-text="'name'"
                      :item-value="'id'"
                      label="Services..."
                      hide-details
            >
              <template v-slot:item="slotProps">
                  <div>
                    <v-avatar size="30" :color="slotProps.item.background_color">
                      <v-img :src="getImageLink(slotProps.item.image)" contain></v-img>
                    </v-avatar>
                  </div>
                   &nbsp;
                {{ slotProps.item.name }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-text-field outlined dense
                          v-model="filter.amount"
                          label="Montant..."
                          type="number"
                          hide-details
            >
            </v-text-field>
          </v-col>

          <v-col
              cols="12"
              v-if="
              in_array(filter.types, 'voucher') ||
              in_array(filter.types, 'Idoom') ||
              in_array(filter.types, '4G')
            "
          >
            <v-chip-group v-model="filter.with_sms" column>
              <v-chip filter color="blue" dark value="-1" active-class="white--text">Tous</v-chip>
              <v-chip filter color="orange" dark value="0" active-class="white--text"
              >Sans SMS
              </v-chip
              >
              <v-chip filter color="success" dark value="1" active-class="white--text"
              >Avec SMS
              </v-chip
              >
            </v-chip-group>

            <v-checkbox hide-details label="Aucun sms envoyé" v-model="filter.no_sms"/>
          </v-col>

          <v-col cols="12">
            <v-combobox outlined dense
                        class="mt-3"
                        label="Téléphone "
                        v-model="filter.phone"
                        hide-details
                        :loading="phonesLoading"
                        :items="phones"
            ></v-combobox>
          </v-col>

          <v-col cols="12" v-if="in_array(filter.types, 'voucher')">
            <v-select outlined dense
                      multiple
                      chips
                      deletable-chips
                      small-chips
                      clearable
                      :items="suppliers"
                      v-model="filter.suppliers"
                      :item-text="'name'"
                      :item-value="'id'"
                      label="Suppliers..."
                      hide-details
            >
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-dialog
                ref="dialogStartDate"
                v-model="modalStartDate"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense
                              v-model="filter.startDate"
                              label="Date début"
                              hide-details
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="filter.startDate"
                  @input="[(filter.endDate = null), (modalStartDate = false)]"
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-dialog
                ref="dialogEndDate"
                v-model="modalEndDate"
                @click="modalEndDate = false"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense
                              v-model="filter.endDate"
                              label="Date fin"
                              hide-details
                              :disabled="!filter.startDate"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  @input="modalEndDate = false"
                  v-model="filter.endDate"
                  :min="filter.startDate"
              >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="12">
            <v-radio-group v-model="filter.debit" row label="Debit from :">
              <v-radio label="Service balance" value="debit_from_service_balance"></v-radio>
              <v-radio label="Service balance credit" value="debit_from_service_balance_credit"></v-radio>
              <v-radio label="Balance" value="debit_from_balance"></v-radio>
              <v-radio label="Balance credit" value="debit_from_balance_credit"></v-radio>
            </v-radio-group>
          </v-col>


        </v-row>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn
            color="primary"
            :disabled="isDisabled"
            depressed
            block
            @click="handleFilter"
        >
          Recherche
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ["filter"],
  data() {
    return {
      dialog: false,

      modalStartDate: false,
      modalEndDate: false,

      phonesLoading: false,

      loadingUsers1: false,
      loadingUsers2: false,
      loadingUsers3: false,

      search1: '',
      search2: '',
      search3: '',

      // Types
      types: [
        {
          id: "flexy",
          name: "Flexy",
        },
        {
          id: "sent_refill",
          name: "Solde envoyé",
        },
        {
          id: "received_refill",
          name: "Solde reçu",
        },
        {
          id: "voucher",
          name: "Voucher",
        },
        {
          id: "Idoom",
          name: "Carte Idoom",
        },
        {
          id: "4G",
          name: "Carte 4G",
        },
        {
          id: "refund",
          name: "Remboursé",
        },
      ],

      status: [
        {
          label: "En attente",
          value: "PENDING",
        },

        {
          label: "Acceptée",
          value: "OK",
        },
        {
          label: "Refusée",
          value: "REFUNDED",
        }
      ],

      phones: [],

      users1: [],
      users2: [],
      users3: [],
    };
  },
  methods: {
    handleFilter() {
      this.dialog = false;
      this.$emit("filter", this.filter);
    },
    clearFilter() {
      this.props.filter = {};
    },

    getPhones() {
      this.phonesLoading = true;
      HTTP.get("/v1/support/phones")
          .then((res) => {
            this.phones = res.data;
            this.phonesLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.phonesLoading = false;
          });
    },

    getUsers(search, usersKey, loadingKey) {
      if (search === '') {
        this[usersKey] = [];
        return;
      }
      this[loadingKey] = true;
      HTTP.get("/v1/support/users", {
        params: {
          query: search,
        },
      }).then((res) => {
        this[usersKey] = res.data;
        this[loadingKey] = false;
      }).catch((e) => {
        console.log(e);
        this[loadingKey] = false;
      });
    },
    getServices() {
      this.$store.dispatch("fetchServices", {
        filter: {
          size: 1000,
        },
      });
    },
  },

  watch: {
    search1(value) {
      if (value && value.length >= 3) {
        this.getUsers(value, 'users1', 'loadingUsers1');
      }
    },
    search2(value) {
      if (value && value.length >= 3) {
        this.getUsers(value, 'users2', 'loadingUsers2');
      }
    },
    search3(value) {
      if (value && value.length >= 3) {
        this.getUsers(value, 'users3', 'loadingUsers3');
      }
    }
  },
  computed: {
    isDisabled() {
      return Object.values(this.filter).every(
          (x) => x === null || x === "" || x.length === 0
      );
    },
    suppliers() {
      return this.$store.getters.getSuppliers;
    },
    owners() {
      return this.$store.getters.getVoucherOwners;
    },
    services() {
      return this.$store.getters.getServices.data;
    },
  },
};
</script>
