<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title>
          Paiement API
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">
          <v-simple-table class="table-border rounded-lg">
            <template v-slot:default>
              <tbody>

              <tr>
                <th>ID Transaction</th>
                <td>{{ apiPayment.transaction_id || "-" }}</td>
              </tr>
              <tr>
                <th>Réponse</th>
                <td>
                  <div class="grey lighten-4 rounded pa-5 my-2">
                    {{ apiPayment.api_response || "-" }}
                  </div>
                </td>
              </tr>

              <tr>
                <th>Date</th>
                <td>
                  {{ apiPayment.created_at ? dateFormat(apiPayment.created_at) : "-" }}
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      apiPayment: {},
    }
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm')
    },
    open(apiPayment) {
      this.dialog = true
      this.apiPayment = apiPayment ?? {}
    }
  }
}
</script>

<style scoped>

</style>