<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card class="text-center">

        <v-card-title>
          <v-spacer />
          <v-icon @click="notConfirmed">mdi-close</v-icon>
        </v-card-title>

        <v-card-text v-if="transaction">

          <v-alert v-if="error" border="left" color="error" dark dismissible>
            {{ error }}
          </v-alert>

          <v-icon color="primary" size="50">
            mdi-information-outline
          </v-icon>

          <h2 class="text--primary mt-3">CONFIRMATION</h2>

          <p class="pt-5 font-weight-medium">
            Voulez-vous vraiment annuler cette Transaction ?
          </p>

          <v-row v-if="['voucher', 'Idoom', '4G'].includes(transaction.type)" align="left" class="mb-4" justify="center">
            <v-col class="text-center align-center" cols="12">
              <p>

              </p>
              <v-switch v-model="force_delete" dense  label="Supprimer meme si un SMS est envoyé" hide-details="" />

              <v-switch v-model="reuse_resource" dense  label="Réutiliser la resource (Voucher, Carte)"
                hide-details="" />



            </v-col>



          </v-row>

          

          <v-row>
            <v-col>
              <v-alert type="info">
                Lorsque vous supprimez une opération, le compte sera remboursé automatiquement,
                avec l'indicateur (Remboursement), l'opération apparaîtra dans l'historique du compte comme étant
                remboursé.
              </v-alert>

           
              <v-switch v-model="force_delete" dense label="Forcer la suppression" hide-details="" v-if="['flexy'].includes(transaction.type)" />
            
              <v-switch v-model="with_refund" dense label="Remboursement" hide-details="" />

              <v-switch v-model="no_commission_refund" dense label="Remboursement sans commission"
                hide-details="" />
              <v-btn class="rounded-lg mt-4" color="primary" depressed large @click="[confirm()]">
                <v-icon left>mdi-check-circle-outline</v-icon>
                Confirmer mon action
              </v-btn>
            </v-col>

          </v-row>




        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      dialog: false,
      is_confirm: false,
      confirm_dialog: false,
      transaction: null,
      btnLoading: false,
      error: "",

      force_delete: false,
      reuse_resource: false,
      with_refund: false,
      no_commission_refund: false,
    };
  },
  methods: {
    open(transaction) {
      this.error = "";
      this.transaction = transaction;

      this.force_delete = false;
      this.with_refund = false;
      this.reuse_resource = false;
      this.no_commission_refund = false;

      this.dialog = true;
    },
    confirm() {
      this.error = "";
      this.btnLoading = true;

      HTTP.delete(
        "v1/support/cancel_transaction/" +
        this.transaction.id
        + "?force_delete=" + this.force_delete
        + "&reuse_resource=" + this.reuse_resource
        + "&with_refund=" + this.with_refund
        + "&no_commission_refund=" + this.no_commission_refund
      )
        .then(() => {
          this.btnLoading = false;
          this.dialog = false;
          this.$successMessage = "Transaction Annulée";
          this.$emit("refresh");
          this.$store.dispatch("fetchAccount");
        })
        .catch((err) => {
          console.log(err);
          this.btnLoading = false;

          if (err.response.data.success === false) {
            this.error = "Impossible d'annuler cette Transaction";
          }
        });
    },
    notConfirmed() {
      this.dialog = false;
    },
  },

  computed: {
    sourceName() {
      if (this.transaction) {
        if (this.transaction.type === "voucher") {
          return "Voucher";
        }

        if (this.transaction.type === "4G" || this.transaction.type === "Idoom") {
          return "Carte";
        }
      }

      return "";
    },
  },
};
</script>